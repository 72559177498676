import {
  Vue,
  Component,
  VModel,
  Prop,
  Ref,
  Watch,
} from 'vue-property-decorator'
import * as tsx from 'vue-tsx-support'
import declination from '@/helpers/declination'
import { IVmodelProps } from '@/interfaces/IVmodelProps'
import './style.scoped.scss'
import { valueFromPath } from '@/helpers/object'

const VuePerfectScrollbar = require('vue-perfect-scrollbar').default

type OptionObject = Record<string, any>

export interface ISelectProps extends IVmodelProps {
  options: OptionObject[] | string[] | number[]
  placeholder?: string
  selectedOnTop?: boolean
  closeOnSelect?: boolean
  disabled?: boolean
  label?: string
  pending?: boolean
  clearable?: boolean
  elementDeclination?: [string, string, string]
  flat?: boolean
  emptyFilterMessage?: string
  noWrap?: boolean
  showSelectionPreview?: boolean
  selectionPreviewTimeout?: number
  appendToBody?: boolean
  state?: boolean | null
  instantUpdate?: boolean

  inputChanged?: (value: string) => void

  $scopedSlots?: tsx.InnerScopedSlots<{
    option?: { option: any; selected: boolean }
    selectedOption?: any
  }>
}

@Component
export default class Select extends tsx.Component<ISelectProps> {
  declare $scopedSlots: tsx.InnerScopedSlots<{
    option?: { option: any; selected: boolean }
    selectedOption?: any
  }>

  @Ref() readonly wayupSelect!: HTMLElement
  @Ref() readonly wayupSelectDropdown!: HTMLElement
  @Ref() readonly wayupSelectInput!: HTMLElement

  @VModel({ required: true })
  selectedValue!: OptionObject | OptionObject[] | string | null

  @Prop({ type: Array, required: true })
  readonly options!: OptionObject[] | string[]

  @Prop({ type: String, default: 'введите текст' })
  readonly placeholder!: string

  @Prop({ type: Boolean, default: false })
  readonly selectedOnTop!: boolean

  @Prop({ type: Boolean, default: true })
  readonly closeOnSelect!: boolean

  @Prop({ type: Boolean, default: false })
  readonly disabled!: boolean

  @Prop({ type: String, default: 'label' })
  readonly label!: string

  @Prop({ type: Array, default: () => ['элемент', 'элемента', 'элементов'] })
  readonly elementDeclination!: [string, string, string]

  @Prop({ type: String, default: 'нет подходящих результатов' })
  readonly emptyFilterMessage!: string

  @Prop({ type: Boolean, default: undefined })
  readonly state?: boolean

  @Prop({ type: Boolean, default: true })
  readonly noWrap!: boolean | null

  @Prop({ type: Boolean, default: true })
  readonly showSelectionPreview!: boolean

  @Prop({ type: Number, default: 500 })
  readonly selectionPreviewTimeout!: number

  @Prop({ type: Boolean, default: false })
  readonly pending!: boolean

  @Prop({ type: Boolean, default: true })
  readonly clearable!: boolean

  @Prop({ type: Boolean, default: false })
  readonly flat!: boolean

  @Prop({ type: Boolean, default: false })
  readonly appendToBody!: boolean

  @Prop({ type: Boolean, default: false })
  readonly instantUpdate!: boolean

  uid: number = -1
  tempValues: Array<OptionObject> = []
  search = ''
  isOpen = false
  showPreview = false
  previewTimeoutHandle: null | ReturnType<typeof setTimeout> = null
  dropdownTop = false
  needChange = false

  get isValueEmpty() {
    return this.tempValues.length === 0
  }

  get formattedOptions(): Array<OptionObject> {
    return this.options.map(option => this.formatOption(option))
  }

  get filteredOptions() {
    if (this.showPreview) {
      return this.formattedOptions.filter(option =>
        this.tempValues.includes(option),
      )
    }

    const filteredArray = this.formattedOptions.filter(
      option =>
        option[this.label].toLowerCase().indexOf(this.search.toLowerCase()) >
        -1,
    )

    if (!this.selectedOnTop) {
      return filteredArray
    }

    return filteredArray.sort((el1, el2) => {
      const el1find = this.tempValues.includes(el1)
      const el2find = this.tempValues.includes(el2)
      return el1find === el2find ? 0 : el1find ? -1 : 1
    })
  }

  get multiselect() {
    return Array.isArray(this.selectedValue)
  }

  @Watch('selectedValue')
  onValueChanged() {
    const getOption = (value: any) => {
      if (value === null || value === undefined || value === '') {
        return null
      }

      return (
        (this.formattedOptions as any[]).find(
          option => this.getHash(JSON.stringify(value)) === option.__id,
        ) || this.formatOption(value)
      )
    }

    if (this.multiselect) {
      this.tempValues = []

      for (const value of this.selectedValue as any[]) {
        this.tempValues.push(getOption(value))
      }
    } else {
      const option = getOption(this.selectedValue)

      if (option) {
        Vue.set(this.tempValues, 0, option)
      } else {
        this.tempValues = []
      }
    }
  }

  mounted() {
    this.uid = (this as any)._uid
    document.addEventListener('mousedown', this.clickHandler)

    this.onValueChanged()
  }

  beforeDestroy() {
    document.removeEventListener('mousedown', this.clickHandler)
  }

  getHash(s: string) {
    return s.split('').reduce(function (a, b) {
      a = (a << 5) - a + b.charCodeAt(0)
      return a & a
    }, 0)
  }

  formatOption(option: OptionObject | string | number) {
    if (typeof option === 'string') {
      return {
        [this.label]: option,
        __id: this.getHash(JSON.stringify(option)),
      }
    }
    if (typeof option === 'number') {
      return {
        [this.label]: option.toString(),
        __id: this.getHash(JSON.stringify(option)),
      }
    }

    if (this.label.includes('.')) {
      const labelValue = valueFromPath(option, this.label)
      return {
        ...option,
        [this.label]: labelValue,
        __id: this.getHash(JSON.stringify(option)),
      }
    } else {
      return {
        ...option,
        __id: this.getHash(JSON.stringify(option)),
      }
    }
  }

  clickHandler(e: Event) {
    const target = e.target as Node
    const dropdown = document.querySelector(`#wayup-dropdown-${this.uid}`)

    if (!target || !dropdown) {
      return
    }

    if (!this.$el.contains(target) && !dropdown.contains(target)) {
      this.closeDropdown()
    }
  }

  focusInput() {
    if (this.isOpen) {
      return
    }
    if (this.previewTimeoutHandle) {
      clearTimeout(this.previewTimeoutHandle)
    }

    this.dropdownTop =
      window.innerHeight - this.wayupSelect.getBoundingClientRect().top < 320

    setTimeout(() => {
      this.wayupSelectDropdown.scrollTop = 0
    }, 1)

    if (this.appendToBody) {
      this.updateDropdown()
    }
    this.isOpen = true
  }

  updateDropdown() {
    setTimeout(() => {
      const dropdown = document.querySelector(
        `#wayup-dropdown-${this.uid}`,
      ) as HTMLElement
      document.body.appendChild(dropdown)
      const parentRect = this.wayupSelect.getBoundingClientRect()
      dropdown.style.width = `${parentRect.width}px`
      dropdown.style.left = `${parentRect.left + window.scrollX}px`

      if (this.dropdownTop) {
        dropdown.style.top = `${
          parentRect.top - dropdown.offsetHeight + window.scrollY + 1
        }px`
      } else {
        dropdown.style.top = `${
          parentRect.top + parentRect.height + window.scrollY - 1
        }px`
      }
    }, 1)
  }

  closeDropdown() {
    if (!this.isOpen) {
      return
    }
    this.isOpen = false
    this.search = ''

    this.syncValues()
  }

  syncValues() {
    if (!this.needChange) {
      return
    }
    this.needChange = false
    if (this.multiselect) {
      const indexes: number[] = []

      for (const value of this.tempValues) {
        indexes.push(this.formattedOptions.findIndex(o => o === value))
      }

      this.selectedValue = (this.options as any[]).filter((el, index) =>
        indexes.includes(index),
      )
    } else {
      this.selectedValue =
        this.options[
          this.formattedOptions.findIndex(o => o === this.tempValues[0])
        ] ?? null
    }
  }

  handleBlur() {
    this.search = ''
  }

  select(option: OptionObject) {
    let needClose = true
    this.needChange = false
    if (this.multiselect) {
      this.needChange = true
      if (this.tempValues.includes(option)) {
        this.tempValues = this.tempValues.filter(el => el !== option)
        needClose = false
      } else {
        this.tempValues.push(option)
      }
    } else if (
      option !== this.tempValues[0] &&
      option.__id !== this.tempValues[0]?.__id
    ) {
      this.needChange = true
      Vue.set(this.tempValues, 0, option)
    }

    if (this.instantUpdate) {
      this.syncValues()
    }

    if (needClose && this.closeOnSelect) {
      this.closeDropdown()
    }
  }

  dropdownIconClick() {
    if (this.isOpen) {
      this.closeDropdown()
    } else {
      this.wayupSelectInput.focus()
    }
  }

  clear() {
    this.search = ''
    this.needChange = true
    this.tempValues = []
    this.closeDropdown()
    this.syncValues()
  }

  handleInput() {
    this.focusInput()
    this.$emit('inputChanged', this.search)
  }

  onClick(e: MouseEvent) {
    if (e.target !== e.currentTarget) {
      return
    }
    this.wayupSelectInput.focus()
  }

  multiselectMouseEnter() {
    if (
      this.isOpen ||
      !this.showSelectionPreview ||
      this.tempValues.length === 1
    ) {
      return
    }

    this.previewTimeoutHandle = setTimeout(() => {
      this.showPreview = true
    }, this.selectionPreviewTimeout)
  }

  multiselectMouseLeave() {
    if (this.previewTimeoutHandle) {
      clearTimeout(this.previewTimeoutHandle)
    }
    this.showPreview = false
  }

  get dropdown() {
    return (
      <transition name="fade">
        {(this.isOpen || this.showPreview) && (
          <VuePerfectScrollbar
            ref="wayupSelectDropdown"
            class={{
              'w-dropdown': true,
              'dropdown-top': this.dropdownTop,
              flat: this.flat,
              append: !this.appendToBody,
            }}
            id={`wayup-dropdown-${this.uid}`}
          >
            <ul id={`wayup-dropdown-ul${this.uid}`}>
              {(this.filteredOptions.length === 0 || this.pending) && (
                <li class="empty-message d-flex justify-content-center">
                  {this.filteredOptions.length === 0 && !this.pending ? (
                    <span>{this.emptyFilterMessage}</span>
                  ) : (
                    <div class="preloader d-flex align-items-center">
                      <b-spinner small />
                    </div>
                  )}
                </li>
              )}
              {this.filteredOptions.map((option, index) => (
                <li
                  key={index}
                  class={[
                    'option',
                    {
                      selected: this.tempValues.some(
                        v => v.__id === option.__id,
                      ),
                    },
                  ]}
                  onClick={() => this.select(option)}
                >
                  <div class="option-content">
                    {this.$scopedSlots.option ? (
                      this.$scopedSlots.option({
                        option,
                        selected: this.tempValues.some(
                          v => v.__id === option.__id,
                        ),
                      })
                    ) : (
                      <div
                        class={['option-default', { 'no-wrap': this.noWrap }]}
                      >
                        {option[this.label]}
                      </div>
                    )}
                  </div>
                  <div>
                    <feather-icon class="icon" icon="CheckIcon" size="18" />
                  </div>
                </li>
              ))}
            </ul>
          </VuePerfectScrollbar>
        )}
      </transition>
    )
  }

  get itemsLabel() {
    if (!this.multiselect) {
      return null
    }

    return (
      <div
        class={[
          'selected-view',
          'd-flex',
          'flex-shrink-0',
          { empty: this.tempValues.length === 0 },
          { single: this.tempValues.length === 1 },
        ]}
        onClick={() => this.wayupSelectInput.focus()}
        onMouseenter={this.multiselectMouseEnter}
        onMouseleave={this.multiselectMouseLeave}
      >
        {this.tempValues.length !== 0 && (
          <span class="selected-view_text flex-shrink-0">
            {declination(this.tempValues.length, [
              'выбран',
              'выбрано',
              'выбрано',
            ])}
          </span>
        )}

        {this.tempValues.length !== 0 && (
          <span
            class={{
              'selected-view_count': true,
              'flex-shrink-0': this.tempValues.length !== 1,
            }}
          >
            {this.tempValues.length === 1
              ? this.tempValues[0][this.label]
              : this.tempValues.length}
          </span>
        )}
        {this.tempValues.length !== 0 && (
          <span class="selected-view_text overflow-ellipsis">
            {declination(this.tempValues.length, this.elementDeclination)}
          </span>
        )}
        {this.tempValues.length === 1 && (
          <div
            class="delete-icon"
            onClick={e => {
              e.stopPropagation()
              this.clear()
            }}
          >
            <feather-icon icon="XIcon" size="13" />
          </div>
        )}
      </div>
    )
  }

  protected render() {
    return (
      <div
        ref="wayupSelect"
        class={{
          'wayup-select': true,
          'is-open': this.isOpen,
          'dropdown-top': this.dropdownTop,
          disabled: this.disabled,
          flat: this.flat,
          success: this.state === true,
          error: this.state === false,
          preview: this.showPreview,
        }}
        role="combobox"
        onClick={this.onClick}
      >
        {this.itemsLabel}

        <input
          ref="wayupSelectInput"
          value={this.search}
          onInput={e => {
            this.search = e.target.value
            this.handleInput()
          }}
          class="input"
          disabled={this.disabled}
          placeholder={
            this.tempValues.length && !this.multiselect ? '' : this.placeholder
          }
          type="text"
          onBlur={this.handleBlur}
          onClick={this.focusInput}
          onFocus={this.focusInput}
        />

        {!this.multiselect && this.tempValues[0] && (
          <div class={['value', { invisible: this.search }]}>
            {this.$scopedSlots.selectedOption ? (
              this.$scopedSlots.selectedOption(this.tempValues[0])
            ) : (
              <div class="value-default">{this.tempValues[0][this.label]}</div>
            )}
          </div>
        )}

        {this.clearable && (
          <button
            onClick={this.clear}
            class={['button', { hidden: this.isValueEmpty }]}
          >
            <feather-icon icon="XIcon" size="18" />
          </button>
        )}

        <div
          class={[
            'dropdown-icon',
            { 'rotate-180': this.isOpen && !this.pending },
          ]}
          onClick={this.dropdownIconClick}
        >
          <transition name="fade">
            {!this.isOpen && this.pending ? (
              <div
                key="preloader"
                class="preloader d-flex align-items-center justify-content-center"
              >
                <b-spinner small />
              </div>
            ) : (
              <feather-icon key="icon" icon="ChevronDownIcon" size="25" />
            )}
          </transition>
        </div>

        {this.dropdown}
      </div>
    )
  }
}
